@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: 10em;
  font-family: "Poppins", sans-serif, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  color: #232323;
  overflow: auto;
  overflow-x: hidden;
}

/*
.background {
  background-image: url("../assets/utils/BackgroundTopographicMap.png"); 
}
*/
.dark ::-moz-selection {
  background: #f3f3f3;
  color: #232323;
}
.dark ::selection {
  background: #f3f3f3;
  color: #232323;
}

::-moz-selection {
  background: #232323;
  color: #f3f3f3;
}

::selection {
  background: #232323;
  color: #f3f3f3;
}

.row {
  --bs-gutter-x: 0;
}

h1 {
  font-weight: 400;
  font-family: "Poppins", sans-serif, sans-serif;
  font-size: calc(100% + 1.8vw + 1.8vh);
  margin: 0;
}

h2 {
  font-weight: 400;
  font-family: "Poppins", sans-serif, sans-serif;
  font-size: calc(100% + 2.4vw + 2.4vh);
  position: relative;
  margin: 0;
  margin-bottom: 0.1em;
}
h2 span {
  font-family: "Kanit", sans-serif;
  position: absolute;
  top: -1.3em;
  left: -0.3em;
  font-size: calc(100% + 1.7vw + 1.7vh);
  color: rgba(0, 0, 0, 0.0666666667);
}
h2 Link,
h2 a {
  text-decoration: none;
  color: #232323;
  cursor: pointer;
}

h3 {
  font-weight: 400;
  font-family: "Poppins", sans-serif, sans-serif;
  font-size: calc(100% + 1.5vw + 1.5vh);
  margin: 0;
}
h3 span {
  font-family: "Kanit", sans-serif;
  font-weight: medium;
  font-size: calc(100% + 1.7vw + 1.7vh);
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  color: #d7942d;
  opacity: 0.5;
}

h4 {
  font-weight: 400;
  margin: 0;
}
h4 span {
  font-family: "Kanit", sans-serif;
  font-weight: medium;
  font-size: calc(100% + 2.5vw + 2.5vh);
  position: absolute;
  top: -3.6em;
  left: -1.8em;
  opacity: 0.2;
}

header p {
  font-family: "Poppins", sans-serif, sans-serif;
}

p {
  font-size: calc(100% + 0.3vw + 0.3vh);
  font-weight: 200;
  margin: 0;
  padding: 0;
}
p span {
  font-family: "Poppins", sans-serif, sans-serif;
  font-weight: 500;
  color: rgba(35, 35, 35, 0.3137254902);
  margin: 0;
  padding: 0;
}
p a,
p link {
  color: #d7942d;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  position: relative;
  padding: 0.2em 0;
}
p a:hover,
p a:active,
p a:focus,
p link:hover,
p link:active,
p link:focus {
  color: #232323;
}

span {
  font-size: calc(100% + 0.2vw + 0.2vh);
  font-weight: 200;
}

.bigger-text {
  font-size: calc(100% + 0.5vw + 0.5vh);
  font-weight: 300;
}
.bigger-text span {
  font-weight: 500;
  color: #d7942d;
}

.margin-top-075 {
  margin-top: 0.75em !important;
}

.smaller-span {
  font-size: calc(100% + 0.002vw + 0.002vh);
  font-weight: 400;
  color: rgba(35, 35, 35, 0.3137254902);
  text-decoration: none;
}

.text-dec-none {
  text-decoration: none;
}

.smaller-text {
  font-size: calc(100% + 0.25vw + 0.25vh);
  font-weight: 200;
}
.smaller-text span {
  font-weight: 200;
  color: #d7942d;
}
.smaller-text a,
.smaller-text link {
  text-decoration: none;
  font-weight: 200;
  color: #d7942d;
  padding: 0;
  box-shadow: none;
  font-size: calc(100% + 0.05vw + 0.05vh);
}
.smaller-text a:hover,
.smaller-text a:active,
.smaller-text a:focus,
.smaller-text link:hover,
.smaller-text link:active,
.smaller-text link:focus {
  text-decoration: none;
  color: #d7942d;
  padding: 0;
  box-shadow: none;
  font-size: calc(100% + 0.05vw + 0.05vh);
}

.bigger-accent {
  font-size: calc(100% + 0.15vw + 0.15vh);
  color: #d7942d;
  font-weight: 500;
  text-decoration: none;
}

.simple-link {
  font-size: calc(100% + 0.3vw + 0.3vh);
  font-weight: 200;
}
.simple-link a,
.simple-link link {
  color: #232323;
  font-weight: 200;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.simple-link .accent {
  font-size: inherit;
  color: #d7942d;
  font-weight: 400;
}
.simple-link a:hover,
.simple-link a:active,
.simple-link a:focus,
.simple-link link:hover,
.simple-link link:active,
.simple-link link:focus {
  padding: 0;
  font-weight: 300;
  color: #232323;
  box-shadow: none;
  text-decoration: underline;
}

.no-effect-link {
  font-size: calc(100% + 0.4vw + 0.4vh);
  font-weight: 300;
}
.no-effect-link a,
.no-effect-link link {
  color: #232323;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.no-effect-link a:hover,
.no-effect-link a:active,
.no-effect-link a:focus,
.no-effect-link link:hover,
.no-effect-link link:active,
.no-effect-link link:focus {
  padding: 0;
  font-weight: 300;
  color: #232323;
  box-shadow: none;
}

.logo {
  font-size: calc(100% + 0.3vw + 0.3vh);
}

.buttonClone,
button {
  background-color: #232323;
  color: #f3f3f3;
  text-decoration: none;
  border: none;
  width: auto;
  border-radius: 0.5em;
  padding: 0.5em 1em;
  font-size: calc(100% + 0.04vw + 0.04vh);
  transition: all 0.2s ease-in-out;
}
.buttonClone Link,
.buttonClone a,
button Link,
button a {
  text-decoration: none;
  color: #f3f3f3;
}

button:hover,
button:active {
  background-color: rgba(35, 35, 35, 0.9568627451);
  color: #fff;
}

.m-b-1 {
  margin-bottom: 1em;
}

thead {
  border-bottom: 3px solid #d7942d;
}

input {
  border: none;
  background-color: #f3f3f3;
  border-bottom: 1px solid #232323;
}

textarea {
  border: none;
  background-color: #f3f3f3;
  border-bottom: 1px solid #232323;
}

.parallax-wrapper {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 10px;
  position: relative;
}

.parallax {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  position: relative;
  transform: translateZ(-5px) scale(1.5);
}

.description {
  font-family: "Poppins", sans-serif, sans-serif;
  font-size: calc(100% + 0.05vw + 0.05vh);
  font-weight: 300;
  margin: 0;
  padding: 0;
}
.description pre,
.description ReactMarkdown {
  font-family: "Poppins", sans-serif, sans-serif;
  font-size: calc(100% + 0.05vw + 0.05vh);
  font-weight: 300;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.description span {
  font-family: "Poppins", sans-serif, sans-serif;
  font-weight: 500;
  color: rgba(35, 35, 35, 0.3137254902);
  margin: 0;
  padding: 0;
}
.description a,
.description link {
  color: #d7942d;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  position: relative;
  padding: 0.2em 0;
}
.description a:hover,
.description a:active,
.description a:focus,
.description link:hover,
.description link:active,
.description link:focus {
  color: #232323;
}

.mt-lg-5 {
  margin-top: 10rem !important;
}

.d-show {
  opacity: 1;
  display: inline-block;
}

.info {
  cursor: default;
}

.project-bg {
  background: rgb(243, 243, 243);
  background: linear-gradient(180deg, rgb(243, 243, 243) 45%, rgb(218, 218, 218) 79%, rgb(160, 160, 160) 99%);
}

.bold {
  font-weight: 500;
}

.italic {
  font-weight: 200;
  font-style: italic;
}

.click {
  cursor: pointer;
}

.landing {
  min-height: 80svh;
  position: relative;
  background-color: #f3f3f3;
}

.landing-shorter {
  min-height: 55svh;
  position: relative;
  background-color: #f3f3f3;
}

.section {
  overflow-x: hidden;
  min-height: 35svh;
  height: auto;
  position: relative;
  text-decoration: none;
  color: #232323;
  background-color: #f3f3f3;
  padding: 0;
  margin: 0;
}
.section Link,
.section a {
  text-decoration: none;
  color: #232323;
}
.section .button Link,
.section .button a {
  text-decoration: none;
  color: #f3f3f3;
}

.section-longer {
  height: auto;
  position: relative;
  text-decoration: none;
  color: #232323;
  background-color: #f3f3f3;
  cursor: pointer;
}

.section-longer-nc {
  height: auto;
  position: relative;
  text-decoration: none;
  color: #232323;
  background-color: #f3f3f3;
}

.section-shorter {
  background-color: #232323;
  color: #f3f3f3;
  min-height: calc(100% + 60svw + 60svh);
  height: auto;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}

.project {
  overflow-x: hidden;
  height: auto;
  min-height: 80svh;
  position: relative;
  text-decoration: none;
  color: #232323;
  cursor: pointer;
  transition: background-color 0.5s ease;
}
.project a,
.project link {
  text-decoration: none;
}

.project-thumbnail {
  box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 0;
  border-radius: 0;
  max-height: 80svh;
}

.swiper {
  --bs-gutter-x: 1.5rem;
  margin-left: 0;
  margin-right: 0;
}
.swiper .swiper-wrapper {
  --bs-gutter-x: 1.5rem;
  margin-left: 0;
  margin-right: 0;
}

.s-swiper::-moz-selection {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

.s-swiper::selection {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

.swiper::-moz-selection, .swiper img::-moz-selection {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

.swiper::selection, .swiper img::selection {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

.parallax {
  height: auto;
  min-height: 80svh;
  position: relative;
}

.profile-img {
  width: 100%;
  position: relative;
}

.rep-image {
  width: 80%;
  position: relative;
  filter: drop-shadow(4px 4px 10px #000000);
}

@media screen and (max-width: 768px) {
  .rep-image {
    width: 100%;
    filter: drop-shadow(4px 4px 10px #000000);
  }
}
.project-container {
  cursor: default;
}

.cursor-default {
  cursor: default;
}

.featured-projecs {
  background-color: #ece6dd;
}

.landing {
  height: 100svh;
  overflow: hidden;
}

.goToTop {
  position: fixed;
  bottom: 7.5px;
  right: 7.5px;
  z-index: 100;
  background-color: #232323;
  color: #f3f3f3;
  border: none;
  border-radius: 50%;
  padding: 0.75em 0.75em;
  font-size: calc(100% + 0.02vw + 0.02vh);
  transition: all 0.2s ease-in-out;
}
.goToTop Link,
.goToTop a {
  text-decoration: none;
  color: #f3f3f3;
}

.photo-card-img {
  position: relative;
  width: 100%;
}

.title-lower {
  top: 0;
}

.section h3 {
  font-size: calc(100% + 1.1vw + 1.1vh);
}

.swiper-slide::-moz-selection, .swiper-slide img::-moz-selection {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

.swiper-slide::selection, .swiper-slide img::selection {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}

@media screen and (min-width: 768px) {
  .photo-card-img {
    width: 75%;
  }
  .title-lower {
    top: 0.3em;
  }
}
@media screen and (min-width: 992px) {
  .photo-card-img {
    width: 55%;
  }
  .card-img-smaller-lg {
    width: 45%;
  }
}
@media screen and (min-width: 1920px) {
  .photo-card-img {
    width: 50%;
  }
  .photo-card-img:nth-child(1) {
    top: -5em;
    width: 30%;
  }
  .photo-card-img:nth-child(3) {
    bottom: -5em;
    width: 30%;
  }
  .card-img-smaller-lg {
    width: 40%;
    height: -moz-fit-content;
    height: fit-content;
  }
  .title-lower {
    top: 1.2em;
  }
}
.dashboard-gallery {
  -moz-columns: 300px;
       columns: 300px;
}

.dashboard-img {
  width: 100%;
  padding: 1em;
  transition: all 1s ease-in-out;
}

.splide__pagination__page.is-active {
  background: #d7942d;
}

.splide__pagination__page {
  background: #7e7e7e;
  opacity: 1;
}

.splide__arrow:hover svg:hover {
  fill: #d7942d;
}

.cursor {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  top: 0;
  left: 0;
  position: fixed;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  pointer-events: none;
}